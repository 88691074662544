<template>
    <div class="main container">
        <hotel-nav />
        <el-tabs type="border-card" style="width: 100%">
            <el-tab-pane label="Upload">
                <div style="display: flex; align-items: baseline;">
                
                <el-upload
                class="upload-demo"
                :on-change="handleChange"
                :on-remove="handleRemove"
                :on-exceed="handleExceed"
                action=""
                :limit="limitUpload"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                :auto-upload="false">

                <el-button type="success" icon="el-icon-upload" @click="hotel">Hotel</el-button>
                <el-button type="success" icon="el-icon-upload" @click="room">Room</el-button>
                <!--<el-button type="primary" icon="el-icon-upload" @click="hotelAttractionImport">Hotel Attraction</el-button>-->

                
                </el-upload>
                <div style="margin-left: 10px; display: flex;">
                    <el-button type="primary" icon="el-icon-upload" @click="hotelImages">Hotel Images</el-button>
                    <el-button type="primary" icon="el-icon-upload" @click="hotelAttractionImport">Hotel Attraction</el-button>
                    <el-button type="primary" icon="el-icon-upload" @click="hotelFacilitiesImport">Hotel Facilities</el-button>
                    <el-button type="primary" icon="el-icon-upload" @click="hotelAmenitiesImport">Hotel Amenities</el-button>
                    <el-button type="primary" icon="el-icon-upload" @click="roomAmenitiesImport">Room Amenities</el-button>
                    <el-button type="primary" icon="el-icon-upload" @click="roomBasisImport">Room Basis</el-button>
                </div>
                <div style="padding: 0 20px">
                    <el-button type="warning" icon="el-icon-s-promotion" @click="PostExcel()">Post</el-button>
                </div>
                </div>
                <div v-if="uploadimage" class="input_hotel">
                    <div class="input_hotel_upload">
                    <ejs-autocomplete v-model="hotelname" style="padding-left: 50px; background-image: url(https://cf.bstatic.com/static/img/cross_product_index/accommodation/07ca5cacc9d77a7b50ca3c424ecd606114d9be75.svg); background-repeat: no-repeat; background-position-x: 15px; background-position-y: center; background-color: white; grid-column-start: 1; grid-column-end: 2;"
                    type="search"
                    placeholder="Select Hotel" :dataSource='hotelnames'>
                    </ejs-autocomplete>
                    </div>
                    <input type="file" @change="onFileChange" multiple/>
                    <el-button @click="onUploadFile" type="primary" icon="el-icon-upload"
                    :disabled="!this.selectedFile">Upload Images</el-button>
                </div>

                
                <!-- Data Display -->
                <el-table :data="da" size="small" border v-if="hotel_table_status"
                height="550"
                style="width: 100%;">                  
                    <el-table-column :min-width="80" prop="HotelID" label="Hotel ID" >
                    </el-table-column>
                    <el-table-column :min-width="150" prop="HotelName" label="Hotel Name">
                    </el-table-column>
                    <el-table-column :min-width="300" prop="HotelDescription" label="Hotel Description">
                    </el-table-column>
                    <el-table-column :min-width="100" prop="HotelType" label="Hotel Type">
                    </el-table-column>
                    <el-table-column :min-width="60" prop="Rating" label="Rating">
                    </el-table-column>
                    <el-table-column :min-width="105" prop="RatingLocation" label="Rating Location">
                    </el-table-column>
                    <el-table-column :min-width="90" prop="RatingRoom" label="Rating Room">
                    </el-table-column>
                    <el-table-column :min-width="100" prop="RatingService" label="Rating Service">
                    </el-table-column>
                    <el-table-column :min-width="100" prop="Area" label="Area">
                    </el-table-column>
                    <el-table-column :min-width="100" prop="District" label="District">
                    </el-table-column>
                    <el-table-column :min-width="100" prop="Street" label="Street">
                    </el-table-column>
                    <el-table-column :min-width="100" prop="City" label="City">
                    </el-table-column>
                    <el-table-column :min-width="100" prop="Country" label="Country">
                    </el-table-column>
                    <el-table-column :min-width="130" prop="PropertyContactNo" label="Property Contact No">
                    </el-table-column>
                    <el-table-column :min-width="100" prop="GoogleMap" label="Google Map">
                    </el-table-column>
                    <el-table-column :min-width="100" prop="SaleStatus" label="Sale Status">
                    </el-table-column>
                    <el-table-column :min-width="100" prop="Policy" label="Policy">
                    </el-table-column>
                    <el-table-column :min-width="80" prop="VAT" label="VAT">
                    </el-table-column>
                    <el-table-column :min-width="80" prop="Taxes" label="Taxes">
                    </el-table-column>
                    <el-table-column :min-width="90" prop="ServiceFees" label="Service Fees">
                    </el-table-column>
                    <el-table-column :min-width="110" prop="TourismDirham" label="Tourism Dirham">
                    </el-table-column>           
                </el-table>

                <el-table :data="da" size="small" border v-if="room_table_status"
                height="550"
                style="width: 100%;">                  
                    
                    <el-table-column :min-width="50" prop="HotelID" label="Hotel ID">
                    </el-table-column>
                    <el-table-column :min-width="80" prop="RoomType" label="Room Type">
                    </el-table-column>
                    <el-table-column :min-width="80" prop="RoomQuality" label="Room Quality">
                    </el-table-column>
                    <el-table-column :min-width="60" prop="RoomSize" label="Room Size">
                    </el-table-column>
                    <el-table-column :min-width="50" prop="BedType" label="Bed Type">
                    </el-table-column>
                    <el-table-column :min-width="50" prop="NoOfPerson" label="No Of Person">
                    </el-table-column>
                    <el-table-column :min-width="80" prop="View" label="View">
                    </el-table-column>
                    <el-table-column :min-width="50" prop="Price" label="Price">
                    </el-table-column>    
                </el-table>

                <el-table :data="da" size="small" border v-if="hotelattraction_import_table_status"
                    height="550"
                    style="width: 100%;">                  
                    <el-table-column :min-width="50" prop="AttractionID" label="Attraction ID" ></el-table-column>
                    <el-table-column :min-width="100" prop="AttractionName" label="Attraction Name"></el-table-column>
                    <el-table-column :min-width="100" prop="AttractionDistance" label="Attraction Distance"></el-table-column>
                </el-table>

                <div class="input_hotel1" v-if="hotelattraction">
                  <label>AttractionName: </label>
                  <el-input type="text" v-model="name"/>
                  <label>AttractionDistance: </label>
                  <el-input type="number" v-model="distance"/>
                  <el-button style="margin-left: 40px;" type="primary" icon="el-icon-s-promotion" @click="uploadSave">Save</el-button>
                </div>
                <div class="input_hotel2" v-if="hotelfacilities">
                  <label>FacilityName: </label>
                  <el-input type="text" v-model="name"/>
                  <el-button style="margin-left: 40px;" type="primary" icon="el-icon-s-promotion" @click="uploadSave">Save</el-button>
                </div>
                <div class="input_hotel2" v-if="hotelamenities">
                  <label>AmenityName: </label>
                  <el-input type="text" v-model="name"/>
                  <el-button style="margin-left: 40px;" type="primary" icon="el-icon-s-promotion" @click="uploadSave">Save</el-button>
                </div>
                <div class="input_hotel2" v-if="roomamenities">
                  <label>AmenityName: </label>
                  <el-input type="text" v-model="name"/>
                  <el-button style="margin-left: 40px;" type="primary" icon="el-icon-s-promotion" @click="uploadSave">Save</el-button>
                </div>
                <div class="input_hotel2" v-if="roombasiss">
                  <label>RoomBasis: </label>
                  <el-input type="text" v-model="name"/>
                  <el-button style="margin-left: 40px;" type="primary" icon="el-icon-s-promotion" @click="uploadSave">Save</el-button>
                </div>

            </el-tab-pane>

            <el-tab-pane label="Configuration">
                
                <el-button type="primary" icon="el-icon-upload" @click="hotelAmenities">Hotel Amenities</el-button>
                <el-button type="primary" icon="el-icon-upload" @click="hotelAttraction">Hotel Attraction</el-button>
                <el-button type="primary" icon="el-icon-upload" @click="hotelFacilities">Hotel Faciliteis</el-button>
                <el-button type="primary" icon="el-icon-upload" @click="roomAmenities" disabled>Room Amenities</el-button>
                <el-button type="primary" icon="el-icon-upload" @click="roombasis">Room Basis</el-button>
                <el-button type="secondary" @click="reset1">Reset</el-button>

                <div v-if="hotelinput" class="input_hotel">
                    <div style="width: 300px; margin-right: 20px;">
                    <ejs-autocomplete v-model="hotelname" style="padding-left: 50px; background-image: url(https://cf.bstatic.com/static/img/cross_product_index/accommodation/07ca5cacc9d77a7b50ca3c424ecd606114d9be75.svg); background-repeat: no-repeat; background-position-x: 15px; background-position-y: center; background-color: white; grid-column-start: 1; grid-column-end: 2;"
                    type="search"
                    placeholder="Select Hotel" :dataSource='hotelnames'>
                    </ejs-autocomplete>
                    </div>
                    <el-button @click="configurationPost" type="primary" icon="el-icon-upload">Post</el-button>
                </div>   
          <br>
          <el-table :data="da" size="small" border v-if="roombasis_table_status"
            height="200"
            style="width: 100%;">                  
            <!--<el-table-column :min-width="50" prop="RoomBasisID" label="RoomBasis ID" >
            </el-table-column>-->
            <el-table-column :min-width="100" prop="RoomBasisName" label="RoomBasis Name">
            </el-table-column>
            <el-table-column :min-width="50" prop="Price" label="Price">
              <template slot-scope="scope"> 
                <el-input type="number" size="small" min="0" v-model="scope.row[scope.column.property]" @change="selectid($event,scope.$index)"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="" label="" align="center" min-width="50">
              <template slot-scope="scope"> 
                <el-button size="small" type="primary" icon="el-icon-s-promotion" @click="SelectRow(scope.$index)">Save</el-button>
              </template>
            </el-table-column>
          </el-table>

          <el-table :data="da" size="small" border v-if="hotelamenities_table_status"
            @selection-change="handleSelectionChange"
            height="550"
            style="width: 100%;">                  
            <el-table-column :min-width="50" prop="AmenityID" label="Amenity ID" >
            </el-table-column>
            <el-table-column :min-width="100" prop="AmenityName" label="Amenity Name">
            </el-table-column>
            <el-table-column type="selection" width="100" align="center"/>
          </el-table>

          <el-table :data="da" size="small" border v-if="roomamenities_table_status"
            @selection-change="handleSelectionChange"
            height="550"
            style="width: 100%;">                  
            <el-table-column :min-width="50" prop="AmenityID" label="Amenity ID" >
            </el-table-column>
            <el-table-column :min-width="100" prop="AmenityName" label="Amenity Name">
            </el-table-column>
            <el-table-column type="selection" width="100" align="center"/>
          </el-table>

          <el-table :data="da" size="small" border v-if="hotelfacilities_table_status"
            @selection-change="handleSelectionChange"
            height="550"
            style="width: 100%;">                  
            <el-table-column :min-width="50" prop="FacilityID" label="Facility ID" >
            </el-table-column>
            <el-table-column :min-width="100" prop="FacilityName" label="Facility Name">
            </el-table-column>
            <el-table-column type="selection" width="100" align="center"/>
          </el-table>

          <el-table :data="da" size="small" border v-if="hotelattraction_table_status"
            @selection-change="handleSelectionChange"
            height="550"
            style="width: 100%;">                  
            <!--<el-table-column :min-width="50" prop="AttractionID" label="Attraction ID"></el-table-column>-->
            <el-table-column :min-width="100" prop="AttractionName" label="Attraction Name"></el-table-column>
            <el-table-column :min-width="100" prop="AttractionDistance" label="Attraction Distance"></el-table-column>
            <el-table-column type="selection" width="100" align="center"/>
          </el-table>
          
          </el-tab-pane>
          <el-tab-pane label="Edit / Update / Delete">
            <el-button type="primary" @click="hotelEdit">Hotel</el-button>
            <el-button type="primary" @click="roomEdit">Room</el-button>
            <el-button type="primary" @click="roombasisEdit">RoomBasis</el-button>
            <el-button type="primary" @click="ImagesEdit">Hotel Images</el-button>
            <el-button type="primary" @click="SpecialPriceEdit">Special Price</el-button>
            <el-button type="secondary" @click="reset">Reset</el-button>
            
            <div v-if="hotelinputedit" class="input_hotel">
              <div style="width: 300px; margin-right: 20px; margin-left: 500px;">
                <ejs-autocomplete v-model="hotelname" style="padding-left: 50px; background-image: url(https://cf.bstatic.com/static/img/cross_product_index/accommodation/07ca5cacc9d77a7b50ca3c424ecd606114d9be75.svg); background-repeat: no-repeat; background-position-x: 15px; background-position-y: center; background-color: white; grid-column-start: 1; grid-column-end: 2;"
                  type="search"
                  placeholder="Select Hotel" :dataSource='hotelnames'>
                </ejs-autocomplete>
              </div>
                <el-button @click="searchtable" type="primary" icon="el-icon-search">Serach</el-button>
            </div>
<!--
            <el-table
                :data="gridData" border
                style="width: 100%">

                 <el-table-column label="Operations" min-width="180">
                  <template slot-scope="{row, index}">
                   <el-button icon="el-icon-edit" @click="setEditMode(row, index)"></el-button>
                   <el-button type="success" icon="el-icon-check" @click="saveRow(row, index)"></el-button>
                  </template>
                </el-table-column>


                <el-table-column label="Name" min-width="180">
                  <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.name">
                    <span slot="content">{{row.name}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column min-wwidth="150" label="Gender">

                   <editable-cell 
                   :show-input="row.editMode"
                   slot-scope="{row}" 
                   editable-component="el-select"
                   close-event="change"
                   v-model="row.gender">

                    <el-tag size="medium" 
                            :type="row.gender === 'M' ? 'primary' : 'danger'" 
                            slot="content">
                            {{row.gender === 'M' ? 'Male': 'Female'}}
                    </el-tag>

                    <template slot="edit-component-slot">
                      <el-option value="M" label="Male"></el-option>
                      <el-option value="F" label="Female"></el-option>
                    </template>
                  </editable-cell>

                </el-table-column>


                <el-table-column label="Birth Date" min-width="250">
                   <editable-cell 
                   :show-input="row.editMode"
                   slot-scope="{row}" 
                   editable-component="el-date-picker"
                   format="yyyy-MM-dd"
                   value-format="yyyy-MM-dd"
                   v-model="row.date">
                    <span slot="content">{{row.date}}</span>
                  </editable-cell>
                </el-table-column>
            </el-table>
-->
            <br>
            <el-table :data="da" size="small" border v-if="hotelEdit_table"
            height="550"
            style="width: 100%;">        
                <el-table-column label="" min-width="75" align="center">
                  <template slot-scope="{row, index}">
                   <img :src="require(`../assets/${img}`)" width="50" height="50" @click="saveRow(row, index)"/>
                  </template>
                </el-table-column>          

                <el-table-column :min-width="100" prop="HotelID" label="Hotel ID">
                </el-table-column>

                <el-table-column :min-width="150" prop="HotelName" label="Hotel Name">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.HotelName">
                    <span slot="content">{{row.HotelName}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="300" prop="HotelDescription" label="Hotel Description">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.HotelDescription">
                    <span slot="content">{{row.HotelDescription}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="120" prop="HotelType" label="Hotel Type">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.HotelType">
                    <span slot="content">{{row.HotelType}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="60" prop="Rating" label="Rating">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.Rating">
                    <span slot="content">{{row.Rating}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="105" prop="RatingLocation" label="Rating Location">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.RatingLocation">
                    <span slot="content">{{row.RatingLocation}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="90" prop="RatingRoom" label="Rating Room">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.RatingRoom">
                    <span slot="content">{{row.RatingRoom}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="100" prop="RatingService" label="Rating Service">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.RatingService">
                    <span slot="content">{{row.RatingService}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="100" prop="Area" label="Area">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.Area">
                    <span slot="content">{{row.Area}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="100" prop="District" label="District">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.District">
                    <span slot="content">{{row.District}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="100" prop="Street" label="Street">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.Street">
                    <span slot="content">{{row.Street}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="100" prop="City" label="City">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.City">
                    <span slot="content">{{row.City}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="100" prop="Country" label="Country">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.Country">
                    <span slot="content">{{row.Country}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="130" prop="PropertyContactNo" label="Property Contact No">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.PropertyContactNo">
                    <span slot="content">{{row.PropertyContactNo}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="100" prop="GoogleMap" label="Google Map">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.GoogleMap">
                    <span slot="content">{{row.GoogleMap}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="100" prop="SaleStatus" label="Sale Status">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.SaleStatus">
                    <span slot="content">{{row.SaleStatus}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="100" prop="Policy" label="Policy">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.Policy">
                    <span slot="content">{{row.Policy}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="80" prop="VAT" label="VAT">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.VAT">
                    <span slot="content">{{row.VAT}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="80" prop="Taxes" label="Taxes">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.Taxes">
                    <span slot="content">{{row.Taxes}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="90" prop="ServiceFees" label="Service Fees">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.ServiceFees">
                    <span slot="content">{{row.ServiceFees}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="110" prop="TourismDirham" label="Tourism Dirham">
                    <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.TourismDirham">
                    <span slot="content">{{row.TourismDirham}}</span>
                  </editable-cell>
                </el-table-column>           
            </el-table>

            <el-table :data="da" size="small" border v-if="roomEdit_table"
              height="550"
              style="width: 100%;">   

                <el-table-column label="" min-width="75" align="center">
                  <template slot-scope="{row, index}">
                   <img :src="require(`../assets/${img}`)" width="50" height="50" @click="saveRow(row, index)"/>
                  </template>
                </el-table-column>

                <el-table-column :min-width="50" prop="RoomID" label="Room ID">
                </el-table-column>
                <el-table-column :min-width="50" prop="HotelID" label="Hotel ID">
                </el-table-column>
                
                <el-table-column :min-width="80" prop="RoomType" label="Room Type">
                  <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.RoomType">
                    <span slot="content">{{row.RoomType}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="80" prop="RoomQuality" label="Room Quality">
                  <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.RoomQuality">
                    <span slot="content">{{row.RoomQuality}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="60" prop="RoomSize" label="Room Size">
                  <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.RoomSize">
                    <span slot="content">{{row.RoomSize}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="50" prop="BedType" label="Bed Type">
                  <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.BedType">
                    <span slot="content">{{row.BedType}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="50" prop="NoOfPerson" label="No Of Person">
                  <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.NoOfPerson">
                    <span slot="content">{{row.NoOfPerson}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="80" prop="View" label="View">
                  <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.View">
                    <span slot="content">{{row.View}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="50" prop="Price" label="Price">
                  <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.Price">
                    <span slot="content">{{row.Price}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="50" prop="SpecialPrice" label="Special Price">
                  <editable-cell :show-input="row.editMode" slot-scope="{row}" v-model="row.SpecialPrice">
                    <span slot="content">{{row.SpecialPrice}}</span>
                  </editable-cell>
                </el-table-column>       

            </el-table>

            <el-table :data="da" size="small" border v-if="roombasisEdit_table"
              height="550"
              style="width: 100%;">   

                <el-table-column label="" min-width="50" align="center">
                  <template slot-scope="{row}">
                   <el-button icon="el-icon-delete" type="danger" @click="delRB(row)" circle></el-button>
                  </template>
                </el-table-column>

                <el-table-column :min-width="50" prop="RoomBasisID" label="RoomBasisID">
                </el-table-column>

                <el-table-column :min-width="50" prop="HotelID" label="HotelID">
                </el-table-column>

                <el-table-column :min-width="50" prop="RoomBasisName" label="RoomBasisName">
                </el-table-column>

                <el-table-column :min-width="50" prop="Price" label="Price">
                </el-table-column>

            </el-table>

            <el-table :data="da" size="small" border v-if="imageEdit_table"
              height="550"
              style="width: 100%;">   

                <el-table-column label="" min-width="80" align="center">
                  <template slot-scope="{row}">
                   <el-button icon="el-icon-delete" type="danger" @click="delImage(row)" circle></el-button>
                  </template>
                </el-table-column>

                <el-table-column :min-width="80" prop="HotelID" label="HotelID">
                </el-table-column>

                <el-table-column :min-width="80" prop="Image" label="Image">
                </el-table-column>

            </el-table>

            <el-table :data="da" size="small" border v-if="SpecialPriceEdit_table"
              height="550"
              style="width: 100%;">   

                <el-table-column label="" min-width="75" align="center">
                  <template slot-scope="{row, index}">
                   <img :src="require(`../assets/${img}`)" width="50" height="50" @click="saveRow(row, index)"/>
                  </template>
                </el-table-column>

                <el-table-column :min-width="80" prop="HotelID" label="HotelID">
                </el-table-column>

                <el-table-column :min-width="100" prop="StartDate" label="Start Date">
                  <editable-cell 
                   :show-input="row.editMode"
                   slot-scope="{row}" 
                   editable-component="el-date-picker"
                   format="yyyy-MM-dd"
                   value-format="yyyy-MM-dd"
                   v-model="row.StartDate">
                    <span slot="content">{{row.StartDate}}</span>
                  </editable-cell>
                </el-table-column>

                <el-table-column :min-width="100" prop="EndDate" label="End Date">
                  <editable-cell 
                   :show-input="row.editMode"
                   slot-scope="{row}" 
                   editable-component="el-date-picker"
                   format="yyyy-MM-dd"
                   value-format="yyyy-MM-dd"
                   v-model="row.EndDate">
                    <span slot="content">{{row.EndDate}}</span>
                  </editable-cell>
                </el-table-column>
            </el-table>

          </el-tab-pane>
          <el-tab-pane label="Special">
            <div class="input_hotel">
              <div class="input_hotel_upload">
              <ejs-autocomplete v-model="hotelname" style="padding-left: 50px; background-image: url(https://cf.bstatic.com/static/img/cross_product_index/accommodation/07ca5cacc9d77a7b50ca3c424ecd606114d9be75.svg); background-repeat: no-repeat; background-position-x: 15px; background-position-y: center; background-color: white; grid-column-start: 1; grid-column-end: 2;"
              type="search"
              placeholder="Select Hotel" :dataSource='hotelnames'>
              </ejs-autocomplete>
              </div>
              <!-- Date inputs starts here-->
            <div style="width: 500px; display: flex;">
              <el-date-picker
                v-model="date_range"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="Start date"
                end-placeholder="End date">
              </el-date-picker>
              <div style="margin-left: 20px;">
                  <el-button type="primary" @click="datesave" icon="el-icon-upload">Save</el-button>
              </div>
            </div>
            </div>

          </el-tab-pane>
        </el-tabs> 
    </div>
</template>

<script>
import EditableCell from "../components/EditableCell.vue";

import axios from "axios";
import HotelNav from "../components/HotelNav.vue";
export default {
    name: "Upoad",
    components: {
    EditableCell,
    HotelNav
},
    data() {
        return {
            limitUpload:1,
            fileTemp:null,
            file:null,
            da:[],
            item: [],
            hotelname: "",
            hotelnames: [],
            name: "",
            distance: 0,
            dalen:0,
            img: "floppy-icon.png",
            date_range: '',
            hotel_table_status: false, 
            room_table_status: false,    
            hotelattraction: false,
            hotelfacilities: false,
            hotelamenities: false,
            roomamenities: false,
            roombasiss: false,
            hotelattraction_import_table_status: false,  
            roombasis_table_status: false,
            hotelinput: false,
            hotelinputedit: false,
            hotelamenities_table_status: false,
            hotelfacilities_table_status: false,
            hotelattraction_table_status: false,
            roomamenities_table_status: false,
            hotelEdit_table: false,
            roomEdit_table: false,
            roombasisEdit_table: false,
            imageEdit_table: false,
            SpecialPriceEdit_table: false,
            uploadtype: "",
            selectedFile: "",
            nooffiles: 0,
            uploadimage: false,
            //hotelid: 2,
            input_i: 0,
            RBPRice: 0,
            RBID: 0,
            //AttractionName: "",
            //AttractionDistance: 0,
            //post table object
            hotelamenitiestable: Object,
            hotelattractiontable: Object,
            hotelfacilitiestable: Object,
            roomamenitiestable: Object,
            //roombasistable: Object,
            /*gridData: [
            {
              date: "2016-05-03",
              name: "Tom",
              gender: "M"
            },
            {
              date: "2016-05-02",
              name: "Lisa",
              gender: "F"
            },
            {
              date: "2016-05-04",
              name: "Jon",
              gender: "M"
            },
            {
              date: "2016-05-01",
              name: "Mary",
              gender: "F"
            }
            ],*/
        }
    },
    methods: {
        setEditMode(row, index) {
            row.editMode = true;
            console.log(row,index);
        },
        async datesave(){
          //console.log(this.date_range[0]);
          //console.log(this.date_range[1]);
          //console.log(this.hotelname);
          if(this.hotelname!="" && this.hotelname!=null && this.date_range!="" && this.date_range!=null){
              try {
                await axios.post("https://www.desertlinktours.com/api/hotels/SpecialDateSave",{
                hotel: this.hotelname, start: this.date_range[0], end: this.date_range[1]
                });
                this.$message({
                type: 'success',
                message: 'Saved'
                });
                this.hotelname=null;
                this.date_range=null
              } catch (error) {
                console.log(error)
              }        
            }
            else{
                this.$message({
                type: 'warning',
                message: 'Select hotel and date'
                });
            }
          
        },
        async delImage(row){
          //console.log(row);
          try {
            await axios.post("https://www.desertlinktours.com/api/hotels/DelImage",{
              data: row.Image
            });
            this.$message({
              type:'success',
              message: row.Image+ " of "+row.HotelID+" is deleted"
              });
            this.getImage();
          } catch (error) {
            console.log(error)
          }
        },
        async delRB(row){
          //console.log(row);
          try {
            await axios.post("https://www.desertlinktours.com/api/hotels/DelRB",{
              data: row
            });
            this.$message({
              type:'success',
              message: row.RoomBasisName+ " of "+row.HotelID+" is deleted"
              });
            this.getRoomBasisEdit();
          } catch (error) {
            console.log(error)
          }
        },
        async saveRow(row, index) {
            row.editMode = false;
            console.log(row,index);
            if(this.hotelEdit_table==true){
            try {
                await axios.post("https://www.desertlinktours.com/api/hotels/UpdateHotel",{
                    data: row
                });
                this.$message({
                type:'success',
                message: "Hotel Record is Updated of "+row.HotelID
                });
                this.getHotel();
            } catch (error) {
                console.log(error);
            }
          }
          if(this.roomEdit_table==true){
            try {
                await axios.post("https://www.desertlinktours.com/api/hotels/UpdateRoom",{
                    data: row
                });
                this.$message({
                type:'success',
                message: "Room Record is Updated of "+row.HotelID
                });
                //this.getRoom();
            } catch (error) {
                console.log(error);
            }
          }
        },
        async PostExcel() {
        //this.del_all_Package();
        if(this.fileTemp!=null)
        {
        try {
            if(this.uploadtype=="hotel"){
                
                await axios.post("https://www.desertlinktours.com/api/hotels/hotelSave", {
                detail: this.da, len: this.dalen
                });

            this.$message({
                type:'success',
                message: "Total No of records Posted = "+this.dalen
            });
            this.uploadtype="";
            this.hotel_table_status=false;
            this.room_table_status=false;
            this.hotelattraction_import_table_status=false;
            this.handleRemove();
            }

            if(this.uploadtype=="room"){
                await axios.post("https://www.desertlinktours.com/api/hotels/roomSave", {
                detail: this.da, len: this.dalen
                });
    
            this.$message({
                type:'success',
                message: "Total No of records Posted = "+this.dalen
            });
            this.uploadtype="";
            this.hotel_table_status=false;
            this.room_table_status=false;
            this.hotelattraction_import_table_status=false;
            this.handleRemove();
            }

            if(this.uploadtype=="hotelAttraction"){
                await axios.post("https://www.desertlinktours.com/api/hotels/hotelAttractionSave", {
                detail: this.da, len: this.dalen
                });
    
            this.$message({
                type:'success',
                message: "Total No of records Posted = "+this.dalen
            });
            this.uploadtype="";
            this.hotel_table_status=false;
            this.room_table_status=false;
            this.hotelattraction_import_table_status=false;
            this.handleRemove();
            }

        } catch (err) {
        console.log(err);
        this.$message({
            type: 'error',
            message: 'Something wrong with Excel data'
          }); 
        }
        }
        else{
            this.$message({
            type: 'warning',
            message: 'Upload Excel file first'
          });    
        }
    },
    
        hotel(){
            this.uploadimage=false;
            this.roombasis_table_status=false;
            this.hotelamenities_table_status=false;
            this.hotelattraction_table_status=false;
            this.hotelattraction=false;
            this.uploadtype="hotel";
            // console.log(this.uploadtype);
    },
        room(){
            this.uploadimage=false;
            this.roombasis_table_status=false;
            this.hotelamenities_table_status=false;
            this.hotelattraction_table_status=false;
            this.hotelattraction=false;
            this.uploadtype="room";
            // console.log(this.uploadtype);
    },
        hotelImages(){
            //this.uploadtype="hotelImages";
            //this.getHotelNames();
            this.hotelnames=[];
            this.getHotelNames();
            this.hotelattraction=false;
            this.uploadimage=true;
            // console.log(this.uploadtype);
    },
        roomAmenities(){
            this.hotelinput=true;
            this.hotelfacilities_table_status=false;
            this.roombasis_table_status=false;
            this.hotelamenities_table_status=false;
            this.hotelattraction_table_status=false;
            this.getRoomAmenities();
            this.roomamenities_table_status=true;
            this.uploadtype="roomAmenities";
            // console.log(this.uploadtype);
    },
        hotelAmenities(){
            this.hotelinput=true;
            this.getHotelAmenities();
            this.hotelfacilities_table_status=false;
            this.roombasis_table_status=false;
            this.hotelattraction_table_status=false;
            this.roomamenities_table_status=false;
            this.hotelamenities_table_status=true;
            this.uploadtype="hotelAmenities";
            // console.log(this.uploadtype);
    },
        hotelAttraction(){
            this.hotelinput=true;
            this.hotelfacilities_table_status=false;
            this.roombasis_table_status=false;
            this.hotelamenities_table_status=false;
            this.roomamenities_table_status=false;
            this.getHotelAttraction();
            this.hotelattraction_table_status=true;
            this.uploadtype="hotelAttraction";
            // console.log(this.uploadtype);
    },
        hotelAttractionImport(){
            this.uploadimage=false;
            //this.roombasis_table_status=false;
            //this.hotelamenities_table_status=false;
            //this.hotelattraction_table_status=false;
            this.roomamenities=false;
            this.hotelamenities=false;
            this.hotelfacilities=false;
            this.roombasiss=false;
            this.hotelattraction=true;
            this.uploadtype="hotelattraction";
    },
        hotelFacilitiesImport(){
            this.uploadimage=false;
            //this.roombasis_table_status=false;
            //this.hotelamenities_table_status=false;
            //this.hotelattraction_table_status=false;
            this.hotelattraction=false;
            this.roomamenities=false;
            this.hotelamenities=false;
            this.roombasiss=false;
            this.hotelfacilities=true;
            this.uploadtype="hotelfacilities";
    },
        hotelAmenitiesImport(){
            this.uploadimage=false;
            //this.roombasis_table_status=false;
            //this.hotelamenities_table_status=false;
            //this.hotelattraction_table_status=false;
            this.hotelattraction=false;
            this.roomamenities=false;
            this.hotelfacilities=false;
            this.roombasiss=false;
            this.hotelamenities=true;
            this.uploadtype="hotelamenities";
    },
        roomAmenitiesImport(){
            this.uploadimage=false;
            //this.roombasis_table_status=false;
            //this.hotelamenities_table_status=false;
            //this.hotelattraction_table_status=false;
            this.hotelattraction=false;
            this.hotelamenities=false;
            this.hotelfacilities=false;
            this.roombasiss=false;
            this.roomamenities=true;
            this.uploadtype="roomamenities";
    },
        roomBasisImport(){
            this.hotelattraction=false;
            this.hotelamenities=false;
            this.hotelfacilities=false;
            this.roomamenities=false;
            this.roombasiss=true;
            this.uploadtype="roombasis";

        },
        hotelFacilities(){
            this.hotelinput=true;
            this.getHotelFacilities();
            this.roombasis_table_status=false;
            this.hotelamenities_table_status=false;
            this.hotelattraction_table_status=false;
            this.hotelfacilities_table_status=true;
            this.uploadtype="hotelFacilities";
            // console.log(this.uploadtype);
    },
        roombasis(){
            this.hotelinput=true;
            this.getRoomBasis();
            this.hotelfacilities_table_status=false;
            this.hotelamenities_table_status=false;
            this.hotelattraction_table_status=false;
            this.roomamenities_table_status=false;
            this.roombasis_table_status=true;
            this.uploadtype="roombasis";
            // console.log(this.uploadtype);
        },
        hotelEdit(){
            this.hotelinputedit=false;
            this.roomEdit_table= false;
            this.imageEdit_table=false;
            this.SpecialPriceEdit_table=false;
            this.roombasisEdit_table=false;
            this.getHotel();
            this.hotelEdit_table= true;
        },
        roomEdit(){
            this.uploadtype="roomedit";
            this.hotelinputedit=true;
            this.hotelEdit_table= false;
            this.imageEdit_table=false;
            this.SpecialPriceEdit_table=false;
            this.roombasisEdit_table=false;
            this.getRoom();
            this.roomEdit_table= true;    
            //console.log("room")     
        },
        roombasisEdit(){
            this.uploadtype="roombasisedit";
            this.hotelinputedit=true;
            this.hotelEdit_table= false;
            this.imageEdit_table=false;
            this.SpecialPriceEdit_table=false;
            this.roomEdit_table= false;
            this.getRoomBasisEdit();
            this.roombasisEdit_table=true;
        },
        ImagesEdit(){
            this.uploadtype="imageedit";
            this.hotelinputedit=true;
            this.hotelEdit_table= false;
            this.roomEdit_table= false;
            this.SpecialPriceEdit_table=false;
            this.roombasisEdit_table=false;
            this.getImage();
            this.imageEdit_table=true;
        },
        SpecialPriceEdit(){
            this.uploadtype="specialpriceedit";
            this.hotelinputedit=true;
            this.hotelEdit_table= false;
            this.roomEdit_table= false;
            this.imageEdit_table=false;
            this.roombasisEdit_table=false;
            this.getSpecialDate();
            this.SpecialPriceEdit_table=true;
        },
        reset(){
          this.hotelinputedit=false;
          this.hotelEdit_table=false;
          this.roomEdit_table=false;
          this.imageEdit_table=false;
          this.SpecialPriceEdit_table=false;
          this.roombasisEdit_table=false;
        },
        reset1(){
          this.hotelinput=false;
          this.hotelfacilities_table_status=false;
          this.roombasis_table_status=false;
          this.hotelamenities_table_status=false;
          this.roomamenities_table_status=false;
          this.hotelattraction_table_status=false;
        },
        async uploadSave(){
          try {
            if(this.uploadtype=="hotelattraction" && this.name!="" && this.distance!=0){
              await axios.post("https://www.desertlinktours.com/api/hotels/hotelattractionSave",{
                name: this.name, distance: this.distance
              });
              this.$message({
                  type: 'success',
                  message: 'Attraction Save Successfully'
                });
                this.name="";
                this.distance=0;
            }
            else if(this.uploadtype=="hotelfacilities" && this.name!=""){
              await axios.post("https://www.desertlinktours.com/api/hotels/hotelfacilitiesSave",{
                name: this.name
              });
              this.$message({
                  type: 'success',
                  message: 'Facility Save Successfully'
                });
                this.name="";
            }
            else if(this.uploadtype=="hotelamenities" && this.name!=""){
              await axios.post("https://www.desertlinktours.com/api/hotels/hotelamenitiesSave",{
                name: this.name
              });
              this.$message({
                  type: 'success',
                  message: 'Amenity Save Successfully'
                });
                this.name="";
            }
            else if(this.uploadtype=="roomamenities" && this.name!=""){
              await axios.post("https://www.desertlinktours.com/api/hotels/roomamenitiesSave",{
                name: this.name
              });
              this.$message({
                  type: 'success',
                  message: 'Amenity Save Successfully'
                });
                this.name="";
            }
            else if(this.uploadtype=="roombasis" && this.name!=""){
              await axios.post("https://www.desertlinktours.com/api/hotels/roombasisSave",{
                name: this.name
              });
              this.$message({
                  type: 'success',
                  message: 'RoomBasis Save Successfully'
                });
                this.name="";
            }
            else{
              this.$message({
                  type: 'error',
                  message: 'Check input fields'
                });
            }
          } catch (error) {
            console.log(error);
          }
        },
        onFileChange(e) {
            this.selectedFile = e.target.files; // accessing file
            this.nooffiles = e.target.length;
            //console.log(e.target.files[0]);
            //console.log(e.target.files);
        },
        async configurationPost(){
          if(this.hotelname!="" && this.hotelname!=null){

            if(this.uploadtype=="hotelAmenities"){
              try {
                await axios.post("https://www.desertlinktours.com/api/hotels/HotelAmenitiesConfig",{
                  detail: this.hotelamenitiestable, len: this.hotelamenitiestable.length, hid: this.hotelname
                });
                this.$message({
                  type: 'success',
                  message: 'Record posted successfully: '+this.hotelamenitiestable.length
                });
                this.hotelname=null;
              } catch (error) {
                console.log(error);
              }
            }
            if(this.uploadtype=="roomAmenities"){
              try {
                await axios.post("https://www.desertlinktours.com/api/hotels/RoomAmenitiesConfig",{
                  detail: this.roomamenitiestable, len: this.roomamenitiestable.length, hid: this.hotelname
                });
                this.$message({
                  type: 'success',
                  message: 'Record posted successfully: '+this.roomamenitiestable.length
                });
                this.hotelname=null;
              } catch (error) {
                console.log(error);
              }
            }
            if(this.uploadtype=="hotelAttraction"){
              try {
                await axios.post("https://www.desertlinktours.com/api/hotels/HotelAttractionConfig",{
                  detail: this.hotelattractiontable, len: this.hotelattractiontable.length, hid: this.hotelname
                });
                this.$message({
                  type: 'success',
                  message: 'Record posted successfully: '+this.hotelattractiontable.length
                });
                this.hotelname=null;
              } catch (error) {
                console.log(error);
              }
            }
            if(this.uploadtype=="hotelFacilities"){
              try {
                await axios.post("https://www.desertlinktours.com/api/hotels/HotelFacilitiesConfig",{
                  detail: this.hotelfacilitiestable, len: this.hotelfacilitiestable.length, hid: this.hotelname
                });
                this.$message({
                  type: 'success',
                  message: 'Record posted successfully: '+this.hotelfacilitiestable.length
                });
                this.hotelname=null;
              } catch (error) {
                console.log(error);
              }
            }
          }
          else{
            this.$message({
                type: 'warning',
                message: 'Select hotel first'
                });
          }

        },
        async searchtable(){
          if(this.hotelname==null){
            this.hotelname="";
          }
          try {
            if(this.uploadtype=="roomedit"){
              const response = await axios.get(`https://www.desertlinktours.com/api/hotels/Room/${this.hotelname}`);
              this.da = response.data;
            }
            if(this.uploadtype=="roombasisedit"){
              const response = await axios.get(`https://www.desertlinktours.com/api/hotels/RoomBasisEdit/${this.hotelname}`);
              this.da = response.data;
            }
            if(this.uploadtype=="imageedit"){
              const response = await axios.get(`https://www.desertlinktours.com/api/hotels/Image/${this.hotelname}`);
              this.da = response.data;
            }
            if(this.uploadtype=="specialpriceedit"){
              const response = await axios.get(`https://www.desertlinktours.com/api/hotels/SpecialDate/${this.hotelname}`);
              this.da = response.data;
            }
          } catch (error) {
            console.log(error);
          }
          
        },
        async onUploadFile() {
            if(this.hotelname!="" && this.hotelname!=null){
            const formData = new FormData();
            //console.log(this.nooffiles);
            //console.log(this.selectedFile.length);
            for(let i=0; i<this.selectedFile.length; i++){
                //console.log(this.selectedFile[i]);
                formData.append("file", this.selectedFile[i]);  // appending file
            }
            //console.log(formData);
            // sending file to the backend
            await axios.post(`https://www.desertlinktours.com/api/hotels/uploadimg/${this.selectedFile.length},${this.hotelname}`, formData)
            .then(res => {
                this.$message({
                type: 'success',
                message: 'Images Upload Successful'
                });
                this.hotelname=null;
              // console.log(res);
            })
            .catch(err => {
              console.log(err);
            });
          }
          else{
            this.$message({
                type: 'warning',
                message: 'select hotel first'
                });
          }
        },
    async getHotelNames() {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/HotelNames`
            );
            this.item = response.data;
            //console.log(this.hotelnames);
            for(let i=0 ;i<=this.item.length;i++) {
              //this.hotelnames.push(this.item[i]["HotelName"]);
                this.hotelnames.push(this.item[i]["HotelID"]);
              }
            } catch (err) {
            console.log(err);
            }
        },
    async getRoomBasis() {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/RoomBasis`
            );
            this.da = response.data;
            //console.log(this.hotelnames);
            } catch (err) {
            console.log(err);
            }
        },
    async getHotelAmenities() {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/HotelAmenities`
            );
            this.da = response.data;
            //console.log(this.hotelnames); 
            } catch (err) {
            console.log(err);
            }
        },
    async getRoomAmenities() {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/RoomAmenities`
            );
            this.da = response.data;
            //console.log(this.hotelnames); 
            } catch (err) {
            console.log(err);
            }
        },
    async getHotelFacilities() {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/HotelFacilities`
            );
            this.da = response.data;
            //console.log(this.hotelnames); 
            } catch (err) {
            console.log(err);
            }
        },
    async getHotelAttraction() {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/HotelAttraction`
            );
            this.da = response.data;
            //console.log(this.hotelnames); 
            } catch (err) {
            console.log(err);
            }
        },
    // get all hotels for edit
    async getHotel() {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/Hotel`
            );
            this.da = response.data;
            //console.log(this.hotelnames); 
            } catch (err) {
            console.log(err);
            }
        },
        //get all room for edit
        async getRoom() {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/Room`
            );
            this.da = response.data;
            //console.log(this.hotelnames); 
            } catch (err) {
            console.log(err);
            }
        },
        //get all roombasis for edit
        async getRoomBasisEdit() {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/RoomBasisEdit`
            );
            this.da = response.data;
            //console.log(this.hotelnames); 
            } catch (err) {
            console.log(err);
            }
        },
        //get all hote images for edit
        async getImage() {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/Image`
            );
            this.da = response.data;
            //console.log(this.hotelnames); 
            } catch (err) {
            console.log(err);
            }
        },
        //get all special dates for edit
        async getSpecialDate() {
            try {
            const response = await axios.get(
           `https://www.desertlinktours.com/api/hotels/SpecialDate`
            );
            this.da = response.data;
            //console.log(this.hotelnames); 
            } catch (err) {
            console.log(err);
            }
        },
        selectid(e,i){
            //console.log(e,i);
            this.input_i=i;
            this.RBPRice=e;  
        },
        async SelectRow(e) {
        //console.log(index);
        //console.log(e);
        //console.log(this.data[e].BookingID);
        this.RBID=this.da[e].RoomBasisID;
        //console.log(this.RBID);
        //console.log(this.bookingrefno);
        if(this.input_i==e){
          //console.log(this.input_i,e);
          if (this.RBPRice==0 ){
          this.$message({
                type: 'error',
                message: 'Price should not be 0'
              });
            }
          else{
          if(this.hotelname!="" && this.hotelname!=null ){
            //console.log(this.RBID + this.RBPRice);
            try {
              await axios.post("https://www.desertlinktours.com/api/hotels/RoomBasisConfig",{
                hotel: this.hotelname, rbid: this.RBID, price: this.RBPRice
              });
              this.$message({
                type: 'success',
                message: 'Saved'
                });
              this.RBPRice=0;
              this.hotelname=null;
              
            } catch (error) {
              console.log(error);
            }
            }
            else{
                this.$message({
                type: 'warning',
                message: 'Select Hotel first'
                });
            }
          //this.getBooking();
          //this.getBooking();
          //this.bookingrefno=0;
          //this.getMailInfo(this.bookingid);  
          //this.postEmail();
          }
        }
        else{
          //console.log("wrong");
          this.$message({
                type: 'error',
                message: 'Enter and save Price one by one'
              });
        }
    },       
    
    handleSelectionChange(selection) {
        //console.log(selection);
        //for(let i=0; i<selection.length;i++)
        //{ 
        //    console.log(selection[i].AmenityID);
        //    console.log(selection[i].AmenityName);
        //}
        if(this.uploadtype=="hotelAmenities"){
            this.hotelamenitiestable=selection;
            //console.log(this.hotelamenitiestable.length);
            }
        if(this.uploadtype=="hotelAttraction"){
            this.hotelattractiontable=selection;
            }
        if(this.uploadtype=="hotelFacilities"){
            this.hotelfacilitiestable=selection;
            }
        if(this.uploadtype=="roomAmenities"){
            this.roomamenitiestable=selection;
            }
    },
        handleChange(file){
            this.fileTemp = file.raw;
            
            if(this.fileTemp){
                if((this.fileTemp.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') 
                    || (this.fileTemp.type == 'application/vnd.ms-excel')){
                    this.importfxx(this.fileTemp);
                } else {
                    this.$message({
                        type:'warning',
                        message:'The format of the attachment is wrong, please delete it and upload it again!'
                    })
                }
            } else {
                this.$message({
                    type:'warning',
                    message:'Please upload an attachment!'
                })
            }
        },
        handleExceed(){
            this.$message({
                type:'warning',
                message:'Exceeded the limit of the maximum number of uploaded files'
            })
            return;
        },
        handleRemove(){
            this.fileTemp = null;
            this.hotel_table_status=false;
            this.room_table_status=false;
            this.hotelattraction_import_table_status=false;
        },
        importfxx() {

            let _this = this;
            //let inputDOM = this.$refs.inputer;
            // Get file data through DOM

            this.file = event.currentTarget.files[0];

            var rABS = false; //Whether to read the file as a binary string
            var f = this.file;
            //display table according to excel
            var v_uploadtype = this.uploadtype;
            if(v_uploadtype=="hotel"){
                this.hotel_table_status=true;
            }
            if(v_uploadtype=="room"){
                this.room_table_status=true;
            }
            if(v_uploadtype=="hotelAttraction"){
                this.hotelattraction_import_table_status=true;
            }
            var reader = new FileReader();
            //if (!FileReader.prototype.readAsBinaryString) {
            FileReader.prototype.readAsBinaryString = function(f) {
                var binary = "";
                var rABS = false; //Whether to read the file as a binary string
                //var pt = this;
                var wb; //Read the completed data
                var outdata;
                var reader = new FileReader();
                reader.onload = function() {
                    var bytes = new Uint8Array(reader.result);
                    var length = bytes.byteLength;
                    for (var i = 0; i < length; i++) {
                        binary += String.fromCharCode(bytes[i]);
                    }
                    var XLSX = require("xlsx");
                    if (rABS) {
                        //wb = XLSX.read(btoa(fixdata(binary)), {
                        //Manual conversion
                        //type: "base64"
                        //});
                    } else {
                        wb = XLSX.read(binary, {
                        type: "binary"
                        });
                    }
                    
                    outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]); //outdata Is what you want
                    console.log('The unprocessed raw data is as follows:');
                    // console.log(outdata);
                    //Data can be processed here
                    let arr = [];
                    if(v_uploadtype=="hotel"){
                    
                    outdata.map(v => {
                        let obj = {}
                        obj.HotelID = v['HotelID']
                        obj.HotelName = v['HotelName']
                        obj.HotelDescription = v['HotelDescription']
                        obj.HotelType = v['HotelType']
                        obj.Rating = v['Rating']
                        obj.RatingLocation = v['RatingLocation']
                        obj.RatingRoom = v['RatingRoom']
                        obj.RatingService = v['RatingService']
                        obj.Area = v['Area']
                        obj.District = v['District']
                        obj.Street = v['Street']
                        obj.City = v['City']
                        obj.Country = v['Country']
                        obj.PropertyContactNo = v['PropertyContactNo']
                        obj.GoogleMap = v['GoogleMap']
                        obj.SaleStatus = v['SaleStatus']
                        obj.Policy = v['Policy']
                        obj.VAT = v['VAT']
                        obj.Taxes = v['Taxes']
                        obj.ServiceFees = v['ServiceFees']
                        obj.TourismDirham = v['TourismDirham']
                        arr.push(obj)
                    });
                    }
                    if(v_uploadtype=="room"){
                
                    outdata.map(v => {
                        let obj = {}
                        //obj.RoomID = v['RoomID']
                        obj.HotelID = v['HotelID']
                        obj.RoomType = v['RoomType']
                        obj.RoomQuality = v['RoomQuality']
                        obj.RoomSize = v['RoomSize']
                        obj.BedType = v['BedType']
                        obj.NoOfPerson = v['NoOfPerson']
                        obj.View = v['View']
                        obj.Price = v['Price']
                        obj.SpecialPrice = v['SpecialPrice']
                        arr.push(obj)
                    });
                    }
                    if(v_uploadtype=="hotelAttraction"){
                
                    outdata.map(v => {
                        let obj = {}
                        obj.AttractionID = v['AttractionID']
                        obj.AttractionName = v['AttractionName']
                        obj.AttractionDistance = v['AttractionDistance']
                        arr.push(obj)
                    });
                    }

                    _this.da=arr;
                    // console.log(arr.length);
                    _this.dalen=arr.length;
                    return arr;
                };
                reader.readAsArrayBuffer(f);
            };
            if (rABS) {
                reader.readAsArrayBuffer(f);
            } else {
                reader.readAsBinaryString(f);
            }
        }
    },
    created() {
        this.getHotelNames();
    },
    mounted() {
    //this.gridData = this.gridData.map(row => {
    this.da = this.da.map(row => {
      return {
        ...row,
        editMode: false
      };
    });
  },
}
</script>

<style scoped>
.main{
    /* padding: 130px 60px 30px 60px; */
    background-color: #f5f5f5;
    min-height: 100vh;
    max-width: 100%;
}
button {
  border: none;
  outline: none;
  /*color: white;
  background-color: black;
}
button:hover {
  background-color: #E4A700 ;*/
}
.input_hotel{
    display: flex; 
    margin-top: 15px;
    align-items: center;
}
.input_hotel_upload{
  width: 300px; 
  margin-right: 20px; 
  margin-left: 200px;
}
.input_hotel1{
  display: flex; 
  margin: 20px 0px 0px 250px; 
  align-items: center; 
  width: 60%;
}
.input_hotel2{
  display: flex; 
  margin: 20px 0px 0px 400px; 
  align-items: center; 
  width: 35%;
}
label{
  margin: 0px 20px;
  font-weight: bold;
}
</style>
<style>
  /*Modify Table background color and text colors*/
  .el-table da, .el-table th.is-leaf,.el-table--border, .el-table--group{
    border-color: #dbfdf1;
    background-color: #66d9ff;
    color: black;
    font-size: 12px;
  }
  /*Modify the highlight of the Element-UI's Table in the mouse hover HOVER*/
  .el-table tbody tr:hover>td {
    background-color:#e6fff8!important
  }
</style>